@use '@/styles/utils/mixins.scss' as *;
.themeLink {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  color: $kiaPolarWhite;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    background-color: $kiaPolarWhite;
    transition: width 0.3s;
  }
  &:hover::before,
  &:focus::before,
  &:active::before {
    width: 100%;
    transition: width 0.3s;
  }
  .menuIcon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: auto;
    right: -15px;
    z-index: 1;
    margin-top: -5px;
    transition: all ease-in-out 0.3s;
  }
  &:hover,
  &:active {
    color: $kiaPolarWhite;
    text-decoration: none !important;
  }
}

.toolsCard {
  margin: 25px auto;
  width: 100%;
  max-width: 280px;
  @media screen and (min-width: 480px) {
    max-width: 100%;
  }
  @media screen and (min-width: 768px) {
    max-width: 280px;
    padding: 0 25px;
  }

  .toolIcon {
    width: 56px;
    height: 56px;
    margin: 0 auto 16px;
  }
  .themeLink {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    @media screen and (max-width: 991.98px) {
      font-size: 16px;
      line-height: 19.2px;
    }
    .menuIcon {
      margin-top: -12px;
      right: -25px;
    }
  }
  .toolDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
  }
}

.serviceWrapper {
  padding-bottom: 20px;
  background-color: $kiaMidnightBlack;
  @include mob-tab() {
    padding: 48px 0px;
  }
  .serviceSwiper {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 24px;
  }
  .swiper-pagination {
    position: static;
    margin-top: 20px;
  }
  .swiper-pagination-bullet {
    background: $kiaBattleshipGrey;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
    width: 25px;
    height: 5px;
    border-radius: 0;
    margin: 0 6px !important;
  }

  .swiper-pagination-bullet-active {
    background: $kiaPolarWhite;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
  }

  .themeLink {
    margin-bottom: 20px;
  }
  .toolsCard {
    margin: 0 auto;
  }
  .toolDescription {
    color: $kiaPolarWhite;
    @media screen and (max-width: 767px) {
      max-width: 250px;
      width: 100%;
      margin: 0 auto;
    }
  }
}
