@use '@/styles/utils/mixins.scss' as *;

.meetTeamCard {
  @media screen and (min-width: 800px) {
    height: 100%;
  }

  .headingElement {
    bottom: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .meetTeamImage {
    display: block;
    width: auto;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 8%;
    height: auto;
  }
}

.email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
