@use '@/styles/utils/mixins.scss' as *;
.dealershipHeroBanner {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 83px);
  }
  &.dealershipHeroBannerNoCTA {
    @media screen and (min-width: 992px) {
      min-height: auto;
    }
  }
}
.mapWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px !important;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .mapWrapContainer {
    margin-bottom: 48px;
  }
}
.mapContactDetails {
  .borderLeft {
    height: auto;
    border-color: $description !important;
    opacity: 1 !important;
  }
  .locationIcon {
    color: $kiaMidnightBlack;
  }
}
